<template>
<div class="inside-bg" style="width:100%">
  <div class="container" style="margin-top:80px;">
    <div class="questionnaire-from">
      <div>
        <div class="header"><h5><b>Job application Questionnaire</b></h5></div>
          <div class="job-details" v-if="job">
          <p class="mb-0 applyfor">Applying for </p>
          <p class="mt-0"><span class="blue-text">{{job.job_designation}}</span> | {{job.company_name}}</p>
        </div>
        <p>Questionnaire <span class="badge text-bg-secondary"><span class="text-success">{{questionnaire.length?ques_index+1:0}}</span> / {{questionnaire.length}}</span></p>
        <div class="ques-wrapper">
          <div class="ques-body mt-2" v-if="questionnaire.length">
            <div class="ques-section"><h6>{{ques_index+1}}. {{questionnaire[ques_index].question}}</h6></div>
            <div class="ans-section">
              <div v-if="questionnaire[ques_index].question_type == 1">
                <v-text-field :type="type" v-model="answer.answer_text" class="w-100" placeholder="..." :key="questionnaire[ques_index].qid" outlined :disabled="disabled"></v-text-field>
              </div>
              <div v-else-if="questionnaire[ques_index].question_type == 2">
                <v-textarea type="text" v-model="answer.answer_text" cols="30" :key="questionnaire[ques_index].qid" placeholder="..." rows="5" outlined :disabled="disabled"></v-textarea>
              </div>
              <div v-else-if="questionnaire[ques_index].question_type == 3">
                <b-form-checkbox v-for="(ans,index) in questionnaire[ques_index].answers" :key="index" :name="ans.answer" :value="ans.answer" :disabled="disabled" @change="handleCheckboxChange(ans.answer)">{{ans.answer}}</b-form-checkbox>
              </div>
              <!-- <div v-else-if="questionnaire[ques_index].answers&&questionnaire[ques_index].answers.length && (questionnaire[ques_index].question_type== 3 || questionnaire[ques_index].question_type== 4)">
                <v-radio-group v-model="radio_answer" label="" column id="radio-group" :disabled="disabled">
                  <v-radio :label="ans.answer" color="primary" :value='ans.ans_id' v-for="(ans,index) in questionnaire[ques_index].answers" :key="index"></v-radio>
                </v-radio-group>
              </div> -->
              <b-form-group v-else-if="questionnaire[ques_index].answers&&questionnaire[ques_index].answers.length && (questionnaire[ques_index].question_type== 3 || questionnaire[ques_index].question_type== 4)" v-slot="{ ariaDescribedby }">
                <b-form-radio v-model="radio_answer" :aria-describedby="ariaDescribedby" :value="ans.ans_id" v-for="(ans,index) in questionnaire[ques_index].answers" :key="index">{{ans.answer}}</b-form-radio>
              </b-form-group>
              <div v-else-if="questionnaire[ques_index].question_type ==5 && autocomplete == true">
                <v-autocomplete v-model="answer.ans_id" :items="values" item-text="text" item-value="id" clearable outlined :disabled="disabled"> 
                  <template slot="item" slot-scope="data">
                    <template>
                      <v-list-item-content>{{data.item.text}}</v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
              </div>
              <div v-else-if="questionnaire[ques_index].question_type == 5 && autocomplete==false">
                <v-autocomplete v-model="answer.answer_text" :items="values" item-text="text" item-value="id" :multiple="multiple" :disabled="disabled" outlined :chips="multiple" :deletable-chips="multiple"></v-autocomplete>
              </div>
              <div v-else-if="questionnaire[ques_index].question_type == 6">
                <v-file-input  v-model="upload_data" label="upload..." :append-icon="$icons.file" prepend-icon="" show-size outlined></v-file-input>
                <p v-if="questionnaire[ques_index].answer" class="text-right mb-0">
                <a class="btn viwbtn" target="_blank" :href="$S3_bucket_url+questionnaire[ques_index].answer"><v-icon>{{$icons.eye}}</v-icon> View current resume</a></p>
              </div>
              <div v-else-if="questionnaire[ques_index].question_type == 7">
                {{ questionnaire[ques_index].question }} <button class="btn btn-primary" @click.prevent="openModal('video', questionnaire[ques_index].question)">Record Answer</button>
              </div>
            </div>
            <div class="note" style="font-size:10px;">
              <p><span style="color:red;">*</span>Prefilled values displayed from your profile. If you want to change pre filled answers, update your profile section and then attempt.</p>
            </div>
            <div v-if="is_error" class="error-feedback">{{err_msg}}</div>
          </div>
        </div>
        <div class="ques-footer" v-if="questionnaire.length">
          <!-- <div class="text-white" v-on:click.prevent="skipQuestion" style="cursor:pointer;margin-left:300px;" v-if="!questionnaire[ques_index].is_mandatory">skip</div> -->
          <!-- <button class="btn btn-primary" v-on:click="goBackQuestion()" v-if="questionnaire.length>1 && ques_index!=0">Prev</button> -->
          <button class="btn btn-primary float-right" v-on:click="submitAnswer('inter')" v-if="questionnaire.length != (ques_index+1)">Next</button>
          <button class="btn btn-primary float-right" v-on:click="submitAnswer('end')" v-else>Submit</button>
        </div>
      </div>
    </div>
    <VideoModal :visume="null"  :open="videoModal.open" @video_path="getVideoPath" :hideDefaultMessage="1" 
    :message="video_question" />
    <b-modal id="ques-success" ref="ques_success" size="sm" title=" " body-bg-variant="primary" bod body-text-variant="light" hide-footer hide-header hide-backdrop centered>
      <div class="container text-center">Your response has been submitted.Thanks!</div>
    </b-modal>
  </div>
</div>
</template>

<script>
const VideoModal = () => import(/* webpackPrefetch: true */ "@/components/VideoModal");
import AwsUpload from "../../utils/s3upload"
import Actions from '../libraries/apiActions.js';
import '../assets/hire.css';
import { mapState, mapGetters } from 'vuex';
import store from '../store';
import { COURSES } from '../store/modules/courses/constants';
import { STATES } from '../store/modules/states/constants';
import { JOB_ROLE } from '../store/modules/jobrole/constants';

var breadcrumbJson = {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": [{
        "@type": "ListItem",
        "position": 1,
        "name": "Home",
        "item": window.location.origin
      },{
        "@type": "ListItem",
        "position": 2,
        "name": "Questionnare",
        "item": window.location.href
      }]
    }
export default {
  name: 'Questionnaire',
  components: {
    VideoModal
  },
  data() {
    return {
      action: new Actions(),
      answer:{},
      radio_answer:null,
      is_error:false,
      err_msg:'',
      upload_data:null,
      message:null,
      botOptions:{
        botTitle: "Questionnaire"
      },
      botTyping: false,
      inputDisable: true,
      question_index:0,
      results: [],
      questionnaire:[],
      ques_index: 0,
      questionnaire_answers:[],
      user:{},
      job:null,
      radio:false,
      values:[],
      autocomplete:true,
      multiple:false,
      disabled:false,
      type:'text',
      videoModal:{
        open:'exit',
      },
      video_question: ""
    }
  },
  metaInfo: {
    script: [
      {type: 'application/ld+json', json: breadcrumbJson},
    ]
  },
  beforeMount(){
    this.id = this.$route.params.id;
    this.action.getUser(this.$cookies.get("hire-seeker").token).then((res) => {
      if (res) {
        this.user = res.user;
      }
    });
    this.getQuestionnaire(this.id);
    this.action.getJobDetailsId({ id: this.id }).then((res) => {
        this.job = res.data[0];
    });
  },
  beforeCreate() {
      this.$store = store;
  },
  created() {
    const apiFn = this.action;
    this.cachedDispatch(COURSES.GET_COURSE, { param: apiFn });
    this.cachedDispatch(STATES.GET_STATES, { param: apiFn });
    this.cachedDispatch(JOB_ROLE.GET_JOB_ROLE, { param: apiFn });
  },
  computed: {
    ...mapGetters(['cachedDispatch']),
    ...mapState({
        courses: state => state.courses.courses.courses,
        locations: state => state.states.locations.locations,
        jobrole: state => state.jobrole.jobrole.jobrole
    }),
    years () {
      const year = new Date().getFullYear()
      return Array.from({length: year - 1950}, (value, index) => 1951 + index)
    }
  },
  methods: {
    openModal : function(type= '', question= ''){
        this.videoModal.open = 'exit';
        this.videoModal.open = type;
        this.video_question = question;
    },
    skipQuestion(){
      this.ques_index = this.ques_index++;
    },
    goBackQuestion(){
      this.answer={};
      this.ques_index = this.ques_index-1;
      this.disabled = false;
      this.getDefaultAnswer();
      this.answer = this.questionnaire_answers[this.ques_index];
    },
    getQuestionnaire(id){
      let token = this.$cookies.get("hire-seeker").token;
      this.action.getQuestionnaire(id,token).then(res => {
        if(res.questionnaire.length){
          res.questionnaire.forEach(ques => {
            if(ques.question_flag == 1){
              this.hasQuestionnaire = true;
              this.questionnaire.push(ques);
            }
          });
          this.getDefaultAnswer();
        }else{
          this.hasQuestionnaire = false;
        }
      });
    },
    cloneValue(data) {
      var clone = {};
      for (var key in data) {
        if (Object.prototype.hasOwnProperty.call(data,key)) //ensure not adding inherited props
          clone[key] = data[key];
        }
      return clone;
    },
    getAnswer(value){
      let url = this.$route.path;
      let url_split = url.split("-");
      let id = url_split[url_split.length - 1];

      if(value != null){
        value.job_id = id;
        value.uid = this.user.id;
        value.company_id  = 0;
        this.results.push(this.cloneValue(value));
      }
      if(this.question_index+1 == this.questionnaire.length){
        if(this.results.length > 0){
          this.action.submitQuestionnaire(this.results,this.$cookies.get("hire-seeker").token).then((res)=>{
            if(res.message == 'Answer submitted successfully'){
              this.isApply = false;
            }
          });
        }

        this.apply_job.src = this.$route.query.src ? this.$route.query.src : "hire-job";
        this.action.applyJob(this.apply_job).then((res) => {
          if (res.status == 1) {
            this.$refs["ques-success"].show();
            setTimeout(() => {
              this.$refs["ques-success"].hide();
            }, 3000);
            this.applied = true;
          }
        });
      }else{
        this.question_index++;
      }

      this.message = this.questionnaire[this.question_index];
    },
    getVideoPath(value){
      this.answer.answer_text = value;
    },
    submitAnswer(type){
      this.is_error = false;
      this.err_msg = '';
      if(this.questionnaire[this.ques_index].question_type == 4){
        if(this.radio_answer == null && this.questionnaire[this.ques_index].deal_breaker == 1){
          this.is_error = true;
          this.err_msg = 'Select answer'
        }else{
          this.setAnswer(type,this.questionnaire[this.ques_index].qid);
        }
      }else if(this.questionnaire[this.ques_index].question_type == 5){
        if((this.autocomplete == true && this.questionnaire[this.ques_index].deal_breaker == 1 && (this.answer.ans_id == 0 || this.answer.ans_id == null )) ||(this.autocomplete == false && this.answer.answer_text == null)){
          this.is_error = true;
          this.err_msg = 'Select answer'
        }else{
          this.setAnswer(type,this.questionnaire[this.ques_index].qid);
        }
      }else{
        if((this.answer.answer_text == null && this.answer.ans_id == null && this.upload_data == null) && this.questionnaire[this.ques_index].deal_breaker == 1){
          this.is_error = true;
          this.err_msg = 'Select answer'
        }else{
          this.setAnswer(type,this.questionnaire[this.ques_index].qid);
        }
      }
    },
    async setAnswer(type,qid){
      this.answer.default = this.questionnaire[this.ques_index].question_details?this.questionnaire[this.ques_index].question_details.default:0;
      if(this.questionnaire[this.ques_index].question_type == 1 || this.questionnaire[this.ques_index].question_type == 2 || this.questionnaire[this.ques_index].question_type == 3||this.questionnaire[this.ques_index].question_type == 7){
        this.answer.qid = this.questionnaire[this.ques_index].qid;
        this.answer.correct_answer_flag = 1;
        this.answer.ans_id = 0;
        this.answer.job_id = this.id;
        this.answer.uid = this.user.id;
        if(qid == this.answer.qid){
          this.questionnaire_answers[this.ques_index] = this.answer;
        }else{
          this.questionnaire_answers.push(this.answer);
        }
        if(type == 'inter'){
          this.answer={};
          this.ques_index++;
          this.disabled = false;
          this.getDefaultAnswer();
        }else{
        this.saveAnswer();
        }
      }else if(this.questionnaire[this.ques_index].question_type == 4){
        this.answer.ans_id = this.radio_answer;
        this.questionnaire[this.ques_index].answers.forEach(answer => {
          if(answer.ans_id == this.answer.ans_id){
            this.answer.answer_text = answer.answer;
            this.answer.correct_answer_flag = answer.correct_answer_flag;
          }
        });
        this.answer.qid = this.questionnaire[this.ques_index].qid;
        this.answer.job_id = this.id;
        this.answer.uid = this.user.id;
        if(qid == this.answer.qid){
          this.questionnaire_answers[this.ques_index] = this.answer;
        }else{
          this.questionnaire_answers.push(this.answer);
        }
        if(type == 'inter'){
          this.radio_answer = null;
          this.answer={};
          this.ques_index++;
          this.disabled = false;
          this.getDefaultAnswer();
        }else{
          this.saveAnswer();
        }
      }else if(this.questionnaire[this.ques_index].question_type == 5){
        this.answer.uid = this.user.id;
        this.answer.qid = this.questionnaire[this.ques_index].qid;
        this.answer.correct_answer_flag = (this.answer&&this.questionnaire[this.ques_index].answers)?((parseInt(this.questionnaire[this.ques_index].answers[0].answer) == this.answer.ans_id)?1:0):0;
        this.answer.job_id = this.id;
        this.answer.answer_text = (this.answer.answer_text)?this.answer.answer_text:null;
        if(qid == this.answer.qid){
          this.questionnaire_answers[this.ques_index] = this.answer;
        }else{
          this.questionnaire_answers.push(this.answer);
        }
       
        if(type == 'inter'){
          this.autocomplete = null;
          this.answer={};
          this.ques_index++;
          this.disabled = false;
          this.getDefaultAnswer();
        }else{
          this.saveAnswer();
        }
      }else if(this.questionnaire[this.ques_index].question_type == 6){
        if(this.upload_data){
          this.loading = true;
          this.answer.ans_id = 0;
          this.answer.uid = this.user.id;
          this.answer.qid = this.questionnaire[this.ques_index].qid;
          this.answer.correct_answer_flag = 1;
          this.answer.job_id = this.id
          const file = this.upload_data;
        const awsUploader = new AwsUpload();
        try {
          var extension = this.upload_data.name.split('.')[this.upload_data.name.split('.').length-1]
          var d = new Date();
          var month = d.getMonth() + 1;
          var day = d.getDate();
          var current_date =d.getFullYear() +"/" +(month < 10 ? "0" : "") +month +"/" +(day < 10 ? "0" : "") +day;
          var timestamp = Math.floor(Date.now() / 1000);
          var filename = 'resume/'+ current_date+"/"+this.$cookies.get("hire-seeker").name + timestamp + "."+extension;
          const response = await awsUploader.uploadToS3(file, filename);
          this.answer.answer_text = response.url;
          this.loading = false;
          this.questionnaire_answers.push(this.answer);
          if(type == 'inter'){
            this.answer={};
            this.ques_index++;
            this.disabled = false;
            this.getDefaultAnswer();
          }else{
            this.saveAnswer();
          }
          
        } catch (error) {
          console.error('Failed to upload file to S3:', error);
        }
       
        }else if(this.answer.answer_text){
          this.answer.ans_id = 0;
          this.answer.uid = this.user.id;
          this.answer.qid = this.questionnaire[this.ques_index].qid;
          this.answer.correct_answer_flag = 1;
          this.answer.job_id = this.id;
          if(qid == this.answer.qid){
            this.questionnaire_answers[this.ques_index] = this.answer;
          }else{
            this.questionnaire_answers.push(this.answer);
          }
          if(type == 'inter'){
            this.answer={};
            this.ques_index++;
            this.disabled = false;
            this.getDefaultAnswer();
          }else{
            this.saveAnswer();
          }
        }else{
          console.log('error');
        }
      }else{
        console.log('error');
      }
    },
    handleCheckboxChange(selectedValue) {
      const index = this.answer.answer_text.indexOf(selectedValue);

      if (index === -1) {
        this.answer.answer_text.push(selectedValue);
      } else {
        this.answer.answer_text.splice(index, 1);
      }
    },
    getDefaultAnswer(){
      let question = this.questionnaire[this.ques_index];
      this.values = [];
      if(question.question_type == 1 || question.question_type == 2){
        if(question.qp_id == 6){
          this.type = 'date';
        }else if(question.qp_id == 14|| question.qp_id == 16|| question.qp_id == 17|| question.qp_id == 19 || question.qp_id == 20 || question.qp_id == 21 || question.qp_id == 24){
          this.type = 'number';
        }else{
          this.type = 'text';
        }
        this.disabled = question.answer?true:false;
        this.answer.answer_text = question.answer!=null?question.answer:'';
      }else if(question.question_type == 3){
        this.disabled = question.answer?true:false;
        this.answer.answer_text = question.answer??[];
      }else if(question.question_type == 4){
        this.radio_answer = question.answer;
        if(question.question_details.default == 1){
          this.radio_answer = question.answer==1?question.answers[question.answers.findIndex(e => e.answer == 'Male')].ans_id:(question.answer==2?question.answers[question.answers.findIndex(e => e.answer == 'Female')].ans_id:question.answers[question.answers.findIndex(e => e.answer == 'Other')].ans_id);
        }else if(question.qp_id == 36){
          this.radio_answer = question.answer?question.answer:null;
        }
        this.disabled = question.answer?true:false;
      }else if(question.question_type == 5){
        if(question.qp_id == 2){
          this.autocomplete = false;
          this.multiple = true;
          this.answer.ans_id = 0;
          this.jobrole.forEach(role => {
            this.values.push({id:role.value,text:role.text});
          })
          this.answer.answer_text= question.answer;
        }else if(question.qp_id == 4 || question.qp_id == 23){
          this.autocomplete = true;
          this.values = this.locations;
          this.answer.ans_id = question.answer;
          this.disabled = question.answer?true:false;
        }else if(question.qp_id == 15){
          this.autocomplete = true;
          this.values = this.courses;
          this.answer.ans_id = question.answer;
          this.disabled = question.answer?true:false;
        }else if(question.qp_id == 7 || question.qp_id == 8 || question.qp_id == 22){
          this.multiple = true;
          this.autocomplete = false;
          this.answer.ans_id = 0;
          this.answer.answer_text = [];
          let answers = question.answer?JSON.parse(question.answer):'';
           console.log(answers);
          if(answers != ''){
            if(Array.isArray(answers)){
              this.answer.answer_text = answers;
            }else if (answers.includes(',')) {
                this.answer.answer_text = answers.split(',');
            } else {
              this.answer.answer_text = [answers];
            }
          }
         
          if(question.qp_id == 7){
            this.action.getLanguages().then(data => {
              if(data.languages.length){
                data.languages.forEach(language => {
                  this.values.push({value:language.language,text:language.language});
                });
              }
            });
          }else if(question.qp_id == 22){
            this.action.getDesignations().then(data => {
              if(data.designations.length){
                data.designations.forEach(designation => {
                  this.values.push({value:designation.designation_id,text:designation.designation_name});
                });
              }
            });
          }else{
            this.action.getSkills().then(data => {
              if(data.skills.length){
                data.skills.forEach(skill => {
                  this.values.push({value:skill.skill,text:skill.skill})
                });
              }
            });
          }
        }else if(question.qp_id == 21){
            this.autocomplete = true;
            this.values = [
                            { id : 1, text: "0 Month(Fresher)" },
                            { id : 6, text: "06 Months" },
                            { id : 12, text: "1 Year" },
                            { id : 24, text: "2 year" },
                            { id : 36, text: "3 year" },
                            { id : 48, text: "4 year" },
                            { id : 60, text: "5 year" },
                            { id : 72, text: "6 year" },
                            { id : 84, text: "6+ year" },
                          ];
            this.answer.ans_id = question.answer;
            this.disabled = question.answer?true:false;
        }else{
          console.log('here');
        }
      }else if(question.question_type == 6){
        this.answer.answer_text = question.answer;
      }
    },

    saveAnswer(){
      let token = this.$cookies.get("hire-seeker").token;
      this.action.saveQuestionnaireAnswers(this.questionnaire_answers,token).then(res => {
        if(res.message == 'Answer submitted successfully'){
        //   this.$refs['ques_success'].show();
        //   setTimeout(() => {
        //     this.$refs['ques_success'].hide();
        //     this.$router.push("/jobs");
        //   },2000)
        this.$router.push({name: "JobList",params: {success:"Your response has been submitted.Thanks!",status:true}});
        }
      });
    }
  }
}
</script>

<style scoped>
.hcp-bot-ui * {
  box-sizing: border-box;
}
.hcp-bot-ui{
  position: fixed;
  z-index: 1000;
  bottom: 1rem;
  right: 25rem;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
}
.hcp-board{
  bottom:0px;
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;
  overflow: hidden;
  width: 500px;
  height: 300px;
  margin-bottom: 10px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 3px 30px 0 rgb(0 0 0 / 15%);
}
.hcp-board-header{
  background-color: #1b53d0;
  display: flex;
  flex: none;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  padding: 5px 15px;
}
.hcp-board-header__title{
  font-weight: 700;
  font-size: .875rem;
  color:#fff;
}
.hcp-board-content{
  background-color: #fff;
  flex-grow: 1;
  overflow: hidden scroll;
}
.hcp-board-content__bubbles{
  min-height:100%;
  padding:1.5rem 1.25rem 1rem;
}
.hcp-msg-bubble{
  padding-bottom:1rem;
  /* display:flex; */
  position:relative;
}
.hcp-msg-avatar {
  width: 32px;
  height: 32px;
  flex: none;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
}
.hcp-msg-avatar__img {
  background-image: url(http://placehold.it/200x200);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  background-size: cover;
  background-repeat: no-repeat;
}
.hcp-msg-bubble .hcp-msg-bubble--bot .hcp-msg-bubble-component {
    margin-right: 2.5rem;
    margin-left: 0.5rem;
}
.hcp-msg-bubble-component {
    font-size: .875rem;
}
.hcp-msg-bubble--bot .hcp-msg-bubble-component__text {
    color: #000;
    background-color: #f0f0f0;
}
.hcp-msg-bubble-component__text {
    position: relative;
    padding: 0.75rem 1rem;
    border-radius: 6px;
}
.hcp-msg-bubble-component__options-wrapper {
    display: flex;
    flex-wrap: wrap;
}
.hcp-mb-button-options__item {
    flex: 0 0 auto;
}
.hcp-mb-button-options__btn {
    display: block;
    overflow: hidden;
    position: relative;
    margin: 0.5rem 0.375rem 0 0;
    padding: 0.25rem 1rem;
    cursor: pointer;
    outline: 0;
    border: 1px solid transparent;
    border-radius: 13px;
    color: inherit;
    font-size: .875rem;
    font-family: inherit;
    text-decoration: none;
    background-color: transparent;
    transition: background-color .15s linear,color .1s linear;
    border-color: #4356e0;
    text-decoration: none !important;
}
.hcp-mb-button-options__btn:hover {
    color: #fff;
    background-color: #4356e0;
}
.hcp-mb-button-options__btn span {
    position: relative;
    z-index: 10;
}
/* .hcp-msg-bubble {
    padding-bottom: 1rem;
    display: flex;
    position: relative;
} */
.hcp-msg-bubble.hcp-msg-bubble--user {
    justify-content: flex-end;
}
.v-label{
  font-size: 15px !important;
}
</style>